import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VAppBar,{attrs:{"flat":"","color":"white","elevation":"0","app":""}},[_c(VToolbarTitle,{staticClass:"font-weight-bold"},[_vm._v(" Ali's Website ")]),_c(VSpacer),_c(VBtn,{attrs:{"text":"","href":"#home"}},[_vm._v("Home")]),_c(VBtn,{attrs:{"text":"","href":"#publications"}},[_vm._v("Publications")]),_c(VBtn,{attrs:{"text":"","href":"#cv"}},[_vm._v("CV")])],1),_c(VMain,[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }